<template>
  <section>
    <div class="spider">
      <div class="spiderweb"></div>
      <div class="body">
        <div class="eye left"></div>
        <div class="eye right"></div>
      </div>
      <div class="legs left">
        <div class="leg"></div>
        <div class="leg"></div>
        <div class="leg"></div>
      </div>
      <div class="legs right">
        <div class="leg"></div>
        <div class="leg"></div>
        <div class="leg"></div>
      </div>
    </div>

    <div class="spider spider-revert">
      <div class="spiderweb"></div>
      <div class="body">
        <div class="eye left"></div>
        <div class="eye right"></div>
      </div>
      <div class="legs left">
        <div class="leg"></div>
        <div class="leg"></div>
        <div class="leg"></div>
      </div>
      <div class="legs right">
        <div class="leg"></div>
        <div class="leg"></div>
        <div class="leg"></div>
      </div>
    </div>

    <div class="spider">
      <div class="spiderweb"></div>
      <div class="body">
        <div class="eye left"></div>
        <div class="eye right"></div>
      </div>
      <div class="legs left">
        <div class="leg"></div>
        <div class="leg"></div>
        <div class="leg"></div>
      </div>
      <div class="legs right">
        <div class="leg"></div>
        <div class="leg"></div>
        <div class="leg"></div>
      </div>
    </div>

    <div class="spider spider-revert">
      <div class="spiderweb"></div>
      <div class="body">
        <div class="eye left"></div>
        <div class="eye right"></div>
      </div>
      <div class="legs left">
        <div class="leg"></div>
        <div class="leg"></div>
        <div class="leg"></div>
      </div>
      <div class="legs right">
        <div class="leg"></div>
        <div class="leg"></div>
        <div class="leg"></div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'Spider',
}
</script>

<style lang="scss" scoped>
.spider {
  position: relative;
  display: inline-block;
  top: 0;

  /* 4. Animatie */
  animation-name: swing;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  transform-origin: top;

  /* Bonus */
  transition: 0.8s ease-in-out;
}

.spider-revert {
  /* 4. Animatie */
  animation-name: swing;
  animation-duration: 2.1s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  transform-origin: top;
}

.spider .spiderweb {
  width: 2px;
  height: 70px;
  margin-left: 49px;
  background: rgba(255, 255, 255, 0.7);
}

.spider .body {
  width: 100px;
  height: 80px;
  background: #222;
  position: relative;
  border-radius: 50%;
}

.spider .body .eye {
  width: 25px;
  height: 25px;
  position: absolute;
  bottom: 20px;
  background: #fff;
  border-radius: 50%;
}

.spider .body .eye.left {
  left: 20px;
}

.spider .body .eye.right {
  right: 20px;
}

.spider .body .eye:after {
  background: #222;
  width: 7px;
  height: 7px;
  content: "";
  display: block;
  margin: 55%;
  border-radius: 50%;

  /* 3. Animatie */
  animation: look 4s infinite;
}

.spider .legs .leg {
  width: 80px;
  height: 40px;
  margin-top: -20px;
  border: 5px solid transparent;
  border-top-color: #333;
  border-radius: 40px 40px 0 0;
}

.spider .legs {
  position: absolute;
  bottom: -10%;
  z-index: -1;
}

.spider .legs.left {
  left: -70%;
}
.spider .legs.right {
  right: -60%;
}

.legs.left .leg:nth-child(1) {
  transform: rotate(10deg);
  margin-left: 10px;
}
.legs.right .leg:nth-child(1) {
  transform: rotate(-10deg);
  margin-left: -10px;
}

.legs.left .leg:nth-child(2) {
  transform: rotate(-20deg);
  margin-left: 20px;
}
.legs.right .leg:nth-child(2) {
  transform: rotate(20deg);
  margin-left: -20px;
}

.legs.left .leg:nth-child(3) {
  transform: rotate(-50deg);
  margin-left: 30px;
}
.legs.right .leg:nth-child(3) {
  transform: rotate(50deg);
  margin-left: -30px;
}

/* 1. Animatie */
@keyframes look {
  0%,
  40%,
  100% {
    transform: translateX(0) scale(.75);
  }

  45%,
  95% {
    transform: translateX(-110%) scale(.75);
  }
}

/* 3. Animatie */
@keyframes swing {
  0%,
  100% {
    transform: translateY(0) scale(.75);
  }

  50% {
    transform: translateY(-20px) scale(.75);
  }
}
</style>
